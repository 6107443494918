import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Card, InputGroup, OverlayTrigger, Popover, ProgressBar, } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { format, setDate, addMonths, endOfMonth, set } from 'date-fns'
import DateTime from 'react-datetime';

import LocaleContext from '../contexts/locale.js'
import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { controlOption_list, checkStrengthType_dict, checkMode_dict } from "../constants/global_definitions"

import { compareOrder, getValue, rgba2hex, checkComponentPresence, myTimestampToDate, getPlanCode, getPlanEndDate, getPlanStartDate, getSelectedElement, getSelectedElementB, getFloatNumber, getFixedNumber, compareDateCustomParam, propComparatorDate, myDateToTimestamp, getElementPreview } from '../../util/ui_utils.js';
import { MyBadge, RiskareaBadge, getMatrix } from "../StructureGraphicElements"

import AlertSection from "../AlertSection.js"
import CheckMatrix from "../CheckMatrix"

import { addSamplingParam, editSamplingParam } from "../../Firebase2/Storage2/dbSamplingPlan"

import firebase from 'firebase/compat/app'
import PrintFooter from '../generic/PrintFooter.js';



const level_list = {
	'level1': { label: 'Liv.1' },
	'level2': { label: 'Liv.2' },
	'level3': { label: 'Liv.3' },
}

const lqa_list = {
	'lqa4': { label: '4%' },
	'lqa6': { label: '6.5%' },
	'lqa10': { label: '10%' }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalSamplingResultPlan = ({ is_new, item, controlgroup, structure, control_mode, canWrite, ui_list, t, item_list, sampling_data, setItem }) => {


	const control_option_list = controlOption_list({ t })
	const check_mode_dict = checkMode_dict({ t })
	const strengthType = checkStrengthType_dict({ t })

	const getEndDate = (start, period_id) => {
		console.log(myTimestampToDate(start), period_id, control_option_list[period_id].month_count)
		let end = addMonths(myTimestampToDate(start), control_option_list[period_id].month_count)
		end = set(endOfMonth(addMonths(myTimestampToDate(start), control_option_list[period_id].month_count)), { hours: 23, minutes: 59 })
		return (firebase.firestore.Timestamp.fromDate(end))
	}

	console.log("TEST----", control_mode, check_mode_dict, control_mode,)

	const newsampling_plan = {
		code: "R" + check_mode_dict[control_mode].code + getPlanCode(item_list.filter(plan => plan.controlgroup_id === controlgroup.id && plan.control_mode === control_mode && plan.check_type === 'results').length) + "_" + controlgroup.code,
		controlgroup_id: controlgroup.id,
		check_period: 1,
		element_list: [],
		startDate: firebase.firestore.Timestamp.fromDate(setDate(new Date(), 1)),
		endDate: getPlanEndDate(firebase.firestore.Timestamp.fromDate(new Date()), control_option_list[1].month_count),
		control_mode: control_mode,
		level: 'level2',
		lqa: 'lqa10',
		check_type: 'results',
		average_mode: 'manual',
		control_strength_mode: 'ordinary',
		use_random_draw_percentage: false,
		use_roomtyperandom_draw_percentage: false,
		user_id: -1
	}
	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contract, structureElements, risk_area_list } = useContext(GlobalDataContext)
	const { contractId } = useContext(GlobalDataContext)


	const componentRef = useRef();
	const childRefAlert = useRef()

	const [showPlanEdit, setShowPlanEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curControlGroup, setCurControlGroup] = useState()
	const [viewData, setViewData] = useState(false)
	const [curPlan, setCurPlan] = useState(newsampling_plan)
	const [risk_area_param_list, setRiskareaParamList] = useState([])
	const [totalValue, setTotalValue] = useState({})

	const handleShowEditPlan = async () => {
		setCurControlGroup(controlgroup)
		setShowPlanEdit(true)
		if (is_new) {

			setCurPlan(newsampling_plan)
		} else {
			setCurPlan({ ...newsampling_plan, ...item })
		}
		setEditedElement(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
		initData(controlgroup)
	}
	const handleClosePlan = () => {
		setShowPlanEdit(false)
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
		documentTitle: `Controllo di risultato: (${format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - ${format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')})
						${controlgroup.name}`,
	});


	const handleSavePlan = async () => {
		console.log("____________________________________SAVE_____________________________", !sampling_data)
		const tlist = Object.assign({}, ...risk_area_param_list.filter(i => i.is_present).map((x) => ({ [x.id]: x })));
		curPlan.element_list = tlist
		curPlan.endDate = getEndDate(curPlan.startDate, control_option_list[curPlan.check_period].month_count)
		let t_element_list = [...item_list]
		if (is_new) {
			console.log("SAVE", curPlan)
			let return_data = await addSamplingParam(domain, contractId, curPlan)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				setShowPlanEdit(false);
				t_element_list.push(return_data)
				console.log("SET LIST", t_element_list)
				setItem(t_element_list)
			}
		} else {
			console.log("EDIT", curPlan)
			let return_data = await editSamplingParam(domain, contractId, curPlan.id, curPlan)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				const c = checkComponentPresence(curPlan, t_element_list, 'id')
				if (c >= 0) {
					t_element_list.splice(c, 1, curPlan)
					setItem(t_element_list)
				}
				setShowPlanEdit(false);
			}
		}
	}

	const onChangeHandlerPlan = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curPlan[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (name === 'average_mode') {
			onChangeAverageMode(value)
		}
		console.log(name, value)
		if (name === 'startDate') {
			setCurPlan({ ...curPlan, [name]: getPlanStartDate(value), endDate: getPlanEndDate(value, control_option_list[curPlan.check_period].month_count) })
		} else if (name === 'check_period') {
			onChangePeriod(value)
			setCurPlan({ ...curPlan, [name]: value, endDate: getPlanEndDate(curPlan.startDate, control_option_list[value].month_count) })
		} else {
			setCurPlan({ ...curPlan, [name]: value })
		}
		setEditedElement(true)
	}



	const onChangeRAparam = (el) => {
		el.ac_check_count = el.data[el.lqa].ac
		el.ac_check_count0 = Math.ceil(el.data[el.lqa].ac * control_option_list[curPlan.check_period].multiplier[0])
		el.ac_check_count1 = Math.ceil(el.data[el.lqa].ac * control_option_list[curPlan.check_period].multiplier[1])
		el.ac_check_count2 = Math.ceil(el.data[el.lqa].ac * control_option_list[curPlan.check_period].multiplier[2])
		let c = checkComponentPresence(el, risk_area_param_list, 'id')
		let tmp_list = [...risk_area_param_list]
		tmp_list.splice(c, 1, el)
		console.log("CHANGE RA PARAM", c, tmp_list)
		setRiskareaParamList(tmp_list)
		setEditedElement(true)
	}


	const initData = (controlgroup) => {
		let elem = {
			...controlgroup,
			size: 0,
			room_count: 0,
			risk_area_summary: {}
		}
		console.log("CONTROL GROUP", controlgroup)
		for (const s of controlgroup.structure_list) {
			const st = structureElements.all_structure_dict[s]
			console.log("structure-RISK AREA SUMMARY", st.risk_area_summary)
			for (const ra_id in st.risk_area_summary) {
				const ra = st.risk_area_summary[ra_id]
				console.log("RA", ra, ra_id)
				elem.size = elem.size + getFloatNumber(ra.size)
				elem.room_count = elem.room_count + ra.room_count
				if (elem.risk_area_summary[ra_id]) {
					elem.risk_area_summary[ra_id].size = getFloatNumber(elem.risk_area_summary[ra_id].size) + getFloatNumber(ra.size)
					elem.risk_area_summary[ra_id].room_count = elem.risk_area_summary[ra_id].room_count + ra.room_count
				} else {
					elem.risk_area_summary[ra_id] = { ...ra }
				}
			}
		}
		setCurControlGroup(elem)
		let row_list = []
		let temp_total = {
			ac_check_count: 0,
			ac_check_count0: 0,
			ac_check_count1: 0,
			ac_check_count2: 0,
			average_count: 0,
			sum_ac_check_count: 0,
			sum_ac_check_count0: 0,
			sum_ac_check_count1: 0,
			sum_ac_check_count2: 0,
			sum_sampling_count: 0,
			sum_sampling_count0: 0,
			sum_sampling_count1: 0,
			sum_sampling_count2: 0,
			sum_average_count: 0,
		}
		console.log(elem.risk_area_summary)
		risk_area_list.map(uira => {
			const ra = elem.risk_area_summary[uira.Id]
			/* 			let onera_fixed_data = {
							name: uira.name[locale],
							order: uira.order,
							color: uira.color,
							id: uira.Id,
						} */

			let one_row = {
				name: uira.name[locale],
				size: 0,
				order: uira.order || 0,
				color: uira.color,
				count: 0,
				average_count: 0,
				average_size: 20,
				check_period: 1,
				level: 'level2',
				lqa: 'lqa10',
				is_present: false,
				id: uira.Id,
				ac_check_count: 0,
				ac_check_count0: 0,
				ac_check_count1: 0,
				ac_check_count2: 0,
				sampling_count: 0,
				sampling_count0: 0,
				sampling_count1: 0,
				sampling_count2: 0,
			}
			console.log("? RA", uira.Id, ra)
			if (ra) {
				one_row = {
					...one_row,
					size: ra.size.toFixed(1),
					count: ra.room_count,
					average_count: Math.ceil((ra.size || 0) / 20),
					is_present: true,
				}
			}
			let room_type_dict = {}
			console.log(curPlan.controlgroup_id)
			if (ui_list.room_controlgroup_dict[curPlan.controlgroup_id]) {

				ui_list.room_controlgroup_dict[curPlan.controlgroup_id].filter(r => r.risk_area && r.risk_area.Id === uira.Id).map(r => {
					const room_type_id = getValue(r.roomtype, 'id')
					const room_type = ui_list.roomtype_dict[room_type_id]
					if (room_type_dict[room_type_id]) {
						room_type_dict[room_type_id].count += 1
					} else {
						room_type_dict[room_type_id] = { count: 1, name: getValue(room_type, 'name'), weight: 0 }
					}
				})
			}
			one_row.room_type_dict = room_type_dict
			if (item && item.element_list[one_row.id]) {
				const ra_data = item.element_list[one_row.id]
				console.log("plan original data", ra_data.average_size, ra_data, ra_data.average_size)
				one_row.average_size = ra_data.average_size
				one_row.average_count = ra_data.average_count
				one_row.check_period = ra_data.check_period
				one_row.level = ra_data.level
				one_row.lqa = ra_data.lqa
				one_row.random_percentage = ra_data.random_percentage || ''
				if (ra_data.room_type_dict) {
					console.error("PROBLEM RECOVER ROOM TYPE LIST")
					one_row.room_type_dict = (ra_data.room_type_dict)
				}
			}
			one_row.data = getMatrix({ count: one_row.average_count, level: one_row.level, strength_mode: curPlan.control_strength_mode })
			console.log("ONE ROW data", one_row.data)
			row_list.push(one_row)
			one_row.ac_check_count = one_row.data[one_row.lqa].ac
			one_row.ac_check_count0 = Math.ceil(one_row.data[one_row.lqa].ac * control_option_list[curPlan.check_period].multiplier[0])
			one_row.ac_check_count1 = Math.ceil(one_row.data[one_row.lqa].ac * control_option_list[curPlan.check_period].multiplier[1])
			one_row.ac_check_count2 = Math.ceil(one_row.data[one_row.lqa].ac * control_option_list[curPlan.check_period].multiplier[2])
			one_row.sampling_count = one_row.data.sampling_count
			one_row.sampling_count0 = Math.ceil(one_row.data.sampling_count * control_option_list[curPlan.check_period].multiplier[0])
			one_row.sampling_count1 = Math.ceil(one_row.data.sampling_count * control_option_list[curPlan.check_period].multiplier[1])
			one_row.sampling_count2 = Math.ceil(one_row.data.sampling_count * control_option_list[curPlan.check_period].multiplier[2])
			temp_total.sum_ac_check_count += one_row.ac_check_count
			temp_total.sum_ac_check_count0 += one_row.ac_check_count0
			temp_total.sum_ac_check_count1 += one_row.ac_check_count1
			temp_total.sum_ac_check_count2 += one_row.ac_check_count2
			temp_total.sum_sampling_count += one_row.data.sampling_count
			temp_total.sum_sampling_count0 += one_row.sampling_count0
			temp_total.sum_sampling_count1 += one_row.sampling_count1
			temp_total.sum_sampling_count2 += one_row.sampling_count2
			temp_total.sum_average_count += one_row.average_count || 0
			//FIXME cancellare quando sistemato
		})
		row_list.sort(compareOrder)
		console.log(row_list)
		setRiskareaParamList(row_list)
		//		setTotalValue(temp_total)
	}



	const onChangeAverageMode = (mode) => {
		if (mode === 'real') {
			const t_element_list = risk_area_param_list.map(one_row => {

				/* 				const data = {}
								for (let r = 0; r < matrix_list.length; r++) {
									if (matrix_list[r].max_index >= one_ra.count && matrix_list[r].min_index <= one_ra.count) {
										data = matrix_list[r]
									}
								} */
				const data = getMatrix({ count: one_row.count, level: one_row.level, strength_mode: curPlan.control_strength_mode })
				console.log("ONE ROW- check data", one_row)
				return {
					...one_row,
					average_size: (one_row.size / one_row.count).toFixed(1),
					average_count: one_row.count,
					data: data,
					ac_check_count: data[one_row.lqa].ac,
					ac_check_count0: Math.ceil(data[one_row.lqa].ac * control_option_list[curPlan.check_period].multiplier[0]),
					ac_check_count1: Math.ceil(data[one_row.lqa].ac * control_option_list[curPlan.check_period].multiplier[1]),
					ac_check_count2: Math.ceil(data[one_row.lqa].ac * control_option_list[curPlan.check_period].multiplier[2]),
					sampling_count: data.sampling_count,
					sampling_count0: Math.ceil(data.sampling_count * control_option_list[curPlan.check_period].multiplier[0]),
					sampling_count1: Math.ceil(data.sampling_count * control_option_list[curPlan.check_period].multiplier[1]),
					sampling_count2: Math.ceil(data.sampling_count * control_option_list[curPlan.check_period].multiplier[2]),
				}
			}
			)
			console.log(t_element_list)
			setRiskareaParamList(t_element_list)
		}

	}


	const onChangePeriod = (period) => {
		let temp_list = [...risk_area_param_list]
		for (const t of temp_list) {
			t.ac_check_count = t.data[t.lqa].ac
			t.ac_check_count0 = Math.ceil(t.data[t.lqa].ac * control_option_list[period].multiplier[0])
			t.ac_check_count1 = Math.ceil(t.data[t.lqa].ac * control_option_list[period].multiplier[1])
			t.ac_check_count2 = Math.ceil(t.data[t.lqa].ac * control_option_list[period].multiplier[2])
			t.sampling_count = t.data.sampling_count
			t.sampling_count0 = Math.ceil(t.data.sampling_count * control_option_list[period].multiplier[0])
			t.sampling_count1 = Math.ceil(t.data.sampling_count * control_option_list[period].multiplier[1])
			t.sampling_count2 = Math.ceil(t.data.sampling_count * control_option_list[period].multiplier[2])
		}
		setRiskareaParamList(temp_list)
		updateTotalValue()
	}




	const onChangeAverageSize = (element, index, el) => {
		console.log(el.value, element, index)
		let temp_list = [...risk_area_param_list]
		temp_list[index].average_size = el.value
		temp_list[index].average_count = Math.ceil(temp_list[index].size / temp_list[index].average_size)

		temp_list[index].data = getMatrix({ count: temp_list[index].average_count, level: curPlan.level, strength_mode: curPlan.control_strength_mode })

		temp_list[index].ac_check_count = temp_list[index].data[temp_list[index].lqa].ac
		temp_list[index].ac_check_count0 = Math.ceil(temp_list[index].data[temp_list[index].lqa].ac * control_option_list[curPlan.check_period].multiplier[0])
		temp_list[index].ac_check_count1 = Math.ceil(temp_list[index].data[temp_list[index].lqa].ac * control_option_list[curPlan.check_period].multiplier[1])
		temp_list[index].ac_check_count2 = Math.ceil(temp_list[index].data[temp_list[index].lqa].ac * control_option_list[curPlan.check_period].multiplier[2])
		temp_list[index].sampling_count = temp_list[index].data.sampling_count
		temp_list[index].sampling_count0 = Math.ceil(temp_list[index].data.sampling_count * control_option_list[curPlan.check_period].multiplier[0])
		temp_list[index].sampling_count1 = Math.ceil(temp_list[index].data.sampling_count * control_option_list[curPlan.check_period].multiplier[1])
		temp_list[index].sampling_count2 = Math.ceil(temp_list[index].data.sampling_count * control_option_list[curPlan.check_period].multiplier[2])

		setRiskareaParamList(temp_list)
		updateTotalValue()
	}


	useEffect(() => {
		updateTotalValue()
	}, [risk_area_param_list, curPlan.level, curPlan.lqa, curPlan.control_strength_mode])

	const updateTotalValue = () => {
		let temp_total = {
			ac_check_count: 0,
			ac_check_count0: 0,
			ac_check_count1: 0,
			ac_check_count2: 0,
			average_count: 0,
			sum_average_count: 0,
			sum_ac_check_count: 0,
			sum_ac_check_count0: 0,
			sum_ac_check_count1: 0,
			sum_ac_check_count2: 0,
			sum_sampling_count: 0,
			sum_sampling_count0: 0,
			sum_sampling_count1: 0,
			sum_sampling_count2: 0,
			//ac_check_count: curPlan.total_dat
		}
		for (let e = 0; e < risk_area_param_list.length; e++) {
			const one_row = risk_area_param_list[e]
			temp_total.sum_ac_check_count += one_row.ac_check_count || 0
			temp_total.sum_ac_check_count0 += one_row.ac_check_count0
			temp_total.sum_ac_check_count1 += one_row.ac_check_count1
			temp_total.sum_ac_check_count2 += one_row.ac_check_count2
			temp_total.sum_sampling_count += one_row.data.sampling_count
			temp_total.sum_sampling_count0 += one_row.sampling_count0
			temp_total.sum_sampling_count1 += one_row.sampling_count1
			temp_total.sum_sampling_count2 += one_row.sampling_count2
			temp_total.sum_average_count += one_row.average_count || 0
			temp_total.average_count += one_row.average_count

		}


		temp_total.data = getMatrix({ count: temp_total.average_count, level: curPlan.level, strength_mode: curPlan.control_strength_mode })
		temp_total.ac_check_count = Math.ceil(temp_total.data[curPlan.lqa].ac)
		temp_total.sampling_count = Math.ceil(temp_total.data.sampling_count)
		temp_total.ac_check_count0 = Math.ceil(temp_total.data[curPlan.lqa].ac * control_option_list[curPlan.check_period].multiplier[0])
		temp_total.ac_check_count1 = Math.ceil(temp_total.data[curPlan.lqa].ac * control_option_list[curPlan.check_period].multiplier[1])
		temp_total.ac_check_count2 = Math.ceil(temp_total.data[curPlan.lqa].ac * control_option_list[curPlan.check_period].multiplier[2])
		temp_total.sampling_count0 = Math.ceil(temp_total.data.sampling_count * control_option_list[curPlan.check_period].multiplier[0])
		temp_total.sampling_count1 = Math.ceil(temp_total.data.sampling_count * control_option_list[curPlan.check_period].multiplier[1])
		temp_total.sampling_count2 = Math.ceil(temp_total.data.sampling_count * control_option_list[curPlan.check_period].multiplier[2])
		console.log("UPDATE TOTAL", temp_total, control_option_list[curPlan.check_period])
		setCurPlan({ ...curPlan, total_data: temp_total })
		setTotalValue(temp_total)
	}


	//---------------------------------
	return (
		<>
			{canWrite ?
				(is_new ?
					<Button className="me-1" variant="primary" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="plus-square" /> {t('samplingplan.labels.new_result_plan')} </Button>
					:
					<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
				)
				:
				<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="eye" /> </Button>
			}
			{curControlGroup ?
				<Modal show={showPlanEdit} onHide={handleClosePlan} fullscreen bsclass="full-modal" backdrop="static" scrollable={true} >
					<AlertSection ref={childRefAlert} />
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							{canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('samplingplan.labels.edit_plan')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.view_plan')}</span>
								</>
							}&nbsp;
							({t('samplingplan.labels.result_check')}

							&nbsp;{format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')}
							)
							: {controlgroup.name}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
							{/* 							<ModalSamplingPlanCopy item={curPlan} setItem={setCurPlan} mode={control_mode} t={t} element_list={item_list} />
 */}							<CheckMatrix />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid ref={componentRef}>
							<style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>
							<Row className="printonly_visible mb-2">
								<Col xs="2">
									<img
										src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
										height="40"
										className="d-inline-block align-center"
										alt={domain_data && domain_data.logo ? domain_data.name : "Claudit"}
									/>
								</Col>
								<Col>
									Piano di Campionamento:
									(Controllo di risultato
									&nbsp;{format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')}
									)
									: {controlgroup.name}&nbsp;

								</Col>
								<Col xs="2">
									{getElementPreview(contract, '40px')}
								</Col>
							</Row>
							<Row>
								{contract.control_validation_mode}
							</Row>
							<Form.Group as={Row} controlId="dpiName" className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
								<Col sm="8">
									<Form.Control type="text" name="code" value={curPlan.code} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formProductBrand" className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.start_plan')}</Form.Label>
								<Col sm="3">
									<DateTime initialValue={myTimestampToDate(curPlan.startDate)} dateFormat='MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerPlan({ name: 'startDate', value: myDateToTimestamp(e.toDate()) })} />
								</Col>
								<Col>
									Data inizio: {format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')}
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.period')} </Form.Label>
								<Col sm="3">
									<Form.Control as="select" className="mb-2" value={curPlan.check_period} name="check_period" onChange={(event) => onChangeHandlerPlan(event.target)} >
										{control_option_list.map((l, k) => (
											<option key={k} value={l.id}>{l.label}</option>
										))}
									</Form.Control>
								</Col>
								<Col>
									Data fine: {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')}
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.average_mode')}</Form.Label>
								<Col>
									<Form.Check type="radio" name="average_mode" value="manual" checked={curPlan.average_mode === 'manual'} label={t('samplingplan.labels.average_mode_manual')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
									<Form.Check type="radio" name="average_mode" value="real" checked={curPlan.average_mode === 'real'} label={t('samplingplan.labels.average_mode_real')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.use_random_percentage')}</Form.Label>
								<Col>
									<Form.Check type="radio" name="use_random_draw_percentage" value={true} checked={curPlan.use_random_draw_percentage} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
									<Form.Check type="radio" name="use_random_draw_percentage" value={false} checked={!curPlan.use_random_draw_percentage} label={t('global.labels.no')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('check.labels.strengthtype')}</Form.Label>
								<Col sm="8">
									<Form.Control as="select" className="mb-2" value={curPlan.control_strength_mode} name="control_strength_mode" onChange={(event) => onChangeHandlerPlan(event.currentTarget)} >
										{Object.keys(strengthType).map((r, rk) => (
											<option key={r} value={r} >{strengthType[r].code} - {strengthType[r].label}</option>
										))}
									</Form.Control>
								</Col>
							</Form.Group>
							{contract.control_validation_mode === 'global' ?
								<>
									<Form.Group as={Row} className="border-bottom mb-1 pb-1">
										<Form.Label column sm="2">{t('samplingplan.labels.prospect')} </Form.Label>
										<Col sm="8">
											<Form.Control as="select" className="mb-2" value={curPlan.level} name="level" onChange={(event) => onChangeHandlerPlan(event.target)} >
												{Object.keys(level_list).map((l, k) => (
													<option key={k} value={l}>{level_list[l].label}</option>
												))}
											</Form.Control>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="border-bottom mb-1 pb-1">
										<Form.Label column sm="2">{t('samplingplan.labels.lqa')} </Form.Label>
										<Col sm="8">
											<Form.Control as="select" className="mb-2" value={curPlan.lqa} name="lqa" onChange={(event) => onChangeHandlerPlan(event.target)} >
												{Object.keys(lqa_list).map((l, k) => (
													<option key={k} value={l}>{lqa_list[l].label}</option>
												))}
											</Form.Control>
										</Col>
									</Form.Group>
								</>
								: ''}
							{curPlan.use_random_draw_percentage ?
								<Form.Group as={Row} className="border-bottom mb-1 pb-1">
									<Form.Label column sm="2">{t('samplingplan.labels.use_roomtypearandom_percentage')}</Form.Label>
									<Col>
										<Form.Check type="radio" name="use_roomtyperandom_draw_percentage" value={true} checked={curPlan.use_roomtyperandom_draw_percentage} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
										<Form.Check type="radio" name="use_roomtyperandom_draw_percentage" value={false} checked={!curPlan.use_roomtyperandom_draw_percentage} label={t('global.labels.no')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
									</Col>
								</Form.Group>
								: ''}
							<Button onClick={() => setViewData(true)}>View Data</Button>
								<Card className="mt-1 border-secondary">

									{risk_area_param_list && risk_area_param_list.length > 0 &&
										<>
											<Card.Header>
												<Row className="text-light text-center mb-3">
													<Col className="bg-secondary pt-2">
														{contract.type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')}
													</Col>
													<Col className="bg-secondary ms-1 pt-2">
														N° Locali
													</Col>
													<Col className="bg-secondary ms-1 pt-2">
														Tot m<sup>2</sup>
													</Col>
													<Col className="bg-secondary ms-1 pt-2">
														Livello di confidenza <br />
														Media m<sup>2</sup>
													</Col>
													<Col className="bg-secondary ms-1 pt-2" >
														Numerosità del lotto
													</Col>
													<Col className="bg-secondary ms-1 pt-2">
														Numerosità del campione
													</Col>
													<Col className="bg-secondary ms-1 pt-2" >
														LQA <br /> Ac
													</Col>
													<Col className="bg-secondary ms-1 pt-2">
														<Row>
															<Col>
																PQA
															</Col>
															<Col>
																PQL
															</Col>
														</Row>
													</Col>
													<Col className="bg-secondary ms-1 pt-2">
														Numero controlli
														<Row className="border-top border-light">
															<Col className="p-1">Sett.</Col>
															<Col className="p-1">Mese</Col>
															<Col className="p-1">Anno</Col>
														</Row>
													</Col>
													{curPlan.use_random_draw_percentage ?
														<Col className="bg-secondary ms-1 pt-2">
															{t('samplingplan.labels.random_percentage')}
														</Col>
														: ''}
													{curPlan.use_random_draw_percentage && curPlan.use_roomtyperandom_draw_percentage ?
														<Col className="bg-secondary ms-1 pt-2">
															{t('samplingplan.labels.roomtyperandom_percentage')}
														</Col>
														: ''}
													{canWrite ?
														<Col className="bg-secondary ms-1 pt-2 text-center" sm="1">
															{t('global.labels.actions')}
														</Col>
														: ''}
												</Row>
											</Card.Header>
											<Card.Body>
												{risk_area_param_list.map((i, key) => (
													<Row key={key} className={`element_row text-center border-bottom mb-1 mt-1 ${i.is_present ? "" : " d-none bg-light text-secondary font-italic"}`}>
														<Col>
															<Row>
																<Col className="p-1 text-start">
																	{i.name}
																</Col>
																<Col className="p-1">
																	<MyBadge color={i.color}>
																		&nbsp;&nbsp;
																	</MyBadge>
																</Col>
															</Row>
														</Col>
														<Col className="p-1" >
															<Row>
																<Col>
																	{i.count}
																</Col>
															</Row>
															<Row>
																<Col>
																	{console.log(i.count, curControlGroup.room_count)}
																	<ProgressBar style={{
																		backgroundColor: rgba2hex(i.color)
																	}} now={getFixedNumber(i.count * 100 / curControlGroup.room_count)} label={`${getFixedNumber(i.count * 100 / curControlGroup.room_count)}%`} />
																</Col>
															</Row>
														</Col>
														<Col>
															<Row>
																<Col>
																	{i.size}
																</Col>
															</Row>
															<Row>
																<Col>
																	<ProgressBar style={{
																		backgroundColor: rgba2hex(i.color)
																	}} now={getFixedNumber(i.size * 100 / curControlGroup.size)} label={`${getFixedNumber(i.size * 100 / curControlGroup.size)}%`} />
																</Col>
															</Row>
														</Col>
														<Col>
															{curPlan.average_mode === 'manual' ?
																<Form.Control name="average_size" min="1" step="0.5" value={i.average_size} type="number" onChange={(event) => onChangeAverageSize(i, key, event.target)} />
																:
																<>{i.average_size}</>}
															{!i.average_size ? <>Impossibile calcolare la media. Controllare i dati inseriti nella struttura.</> : <></>}
														</Col>
														<Col className="p-1 font-weight-bold">
															{i.average_count}
														</Col>
														<Col className="text-start">
															<Row>
																<Col className="text-center">
																	<Badge bg="primary" className="me-1">{level_list[i.level].label}</Badge>
																</Col>
																<Col>
																	{i.data.sampling_count}
																</Col>
															</Row>
															<Row>
																<Col>
																	<ProgressBar style={{
																		backgroundColor: rgba2hex(i.color)
																	}} now={getFixedNumber(i.data.sampling_count * 100 / totalValue.sum_sampling_count)} label={`${getFixedNumber(i.data.sampling_count * 100 / totalValue.sum_sampling_count)}%`} />
																</Col>
															</Row>
														</Col>
														<Col className="text-start">
															<Row>
																<Col className="text-center">
																	<Badge bg="primary">
																		{lqa_list[i.lqa].label}
																	</Badge>
																</Col>
																<Col>
																	{i.data[i.lqa].ac}
																</Col>
															</Row>
														</Col>
														<Col className="p-1" >
															<Row>

																<Col className="p-1">
																	{i.data[i.lqa].pqa} %
																</Col>
																<Col className="p-1">
																	{i.data[i.lqa].pql} %
																</Col>
															</Row>
														</Col>
														<Col>
															<Row>
																<Col className="p-1" >{i.sampling_count0}</Col>
																<Col className="p-1" >{i.sampling_count1}</Col>
																<Col className="p-1" >{i.sampling_count2}</Col>
															</Row>
														</Col>
														{curPlan.use_random_draw_percentage ?
															<Col>
																{i.random_percentage} %
															</Col>
															: ''}
														{curPlan.use_random_draw_percentage && curPlan.use_roomtyperandom_draw_percentage ?
															<Col>

																{i.room_type_dict ?
																	<>
																		<OverlayTrigger trigger={['focus', 'hover']} placement="left-start" overlay={renderPopover(t, i)}>
																			<Badge bg="secondary" className="btn me-1"> % </Badge>
																		</OverlayTrigger>
																	</>
																	: <><FontAwesomeIcon icon="exclamation-triangle" /></>}
															</Col>
															: ''}
														{canWrite ?
															<Col sm="1">
																<ModalRiskAreaParam ui_list={ui_list} functions={{ edit: onChangeRAparam }} is_present={i.is_present} canWrite={canWrite} element={i} t={t} control_option_list={control_option_list} lqa_list={lqa_list} level_list={level_list} curPlan={curPlan} />
															</Col>
															: ''}
													</Row>
												))}
											</Card.Body>
											<Card.Footer className="border-secondary">
												<Row className="text-center">
													<Col>
														Totale globale (somma)
													</Col>
													<Col>
														{curControlGroup.room_count}
													</Col>
													<Col >
														{(curControlGroup.size || 0).toFixed(0)}
													</Col>
													<Col>
													</Col>
													<Col>
														{totalValue.sum_average_count}
													</Col>
													<Col className="font-weight-bold">
														{totalValue.sum_sampling_count}
													</Col>
													<Col className="font-weight-bold">
														{totalValue.sum_ac_check_count}
													</Col>
													<Col>
													</Col>
													<Col>
														<Row>
															<Col className="p-1">
																{totalValue.sum_sampling_count0}
															</Col>
															<Col className="p-1">
																{totalValue.sum_sampling_count1}
															</Col>
															<Col className="p-1">
																{totalValue.sum_sampling_count2}
															</Col>

														</Row>
													</Col>
													{curPlan.use_random_draw_percentage ?
														<Col>
														</Col>
														: ''}
													{curPlan.use_random_draw_percentage && curPlan.use_roomtyperandom_draw_percentage ?
														<Col>
														</Col>
														: ''}
													{canWrite ?
														<Col sm="1">
														</Col>
														: ''}
												</Row>
												{contract.control_validation_mode === 'global' ?
													<Row className="font-weight-bold text-center mt-1 bg-secondary text-light">
														<Col>
															{t('global.labels.total')}
														</Col>
														<Col>
															{curControlGroup.room_count}
														</Col>
														<Col >
															{(curControlGroup.size || 0).toFixed(0)}
														</Col>
														<Col>
														</Col>
														<Col>
															{totalValue.average_count}
														</Col>
														<Col>
															<Row>
																<Col className="text-center">
																	<Badge bg="primary" className="me-1">{level_list[curPlan.level].label}</Badge>
																</Col>
																<Col>
																	{totalValue.sampling_count}
																</Col>

															</Row>
														</Col>
														<Col>
															<Row>
																<Col className="text-center">
																	<Badge bg="primary">
																		{lqa_list[curPlan.lqa].label}
																	</Badge>
																</Col>
																<Col>
																	{totalValue.data[curPlan.lqa].ac_check_count}
																</Col>
															</Row>
														</Col>
														<Col>
															<Row>

																<Col>
																	{totalValue.data[curPlan.lqa].pqa} %
																</Col>
																<Col>
																	{totalValue.data[curPlan.lqa].pql} %
																</Col>
															</Row>
														</Col>
														<Col>
															<Row>
																<Col className="p-1">
																	{totalValue.sampling_count0}
																</Col>
																<Col className="p-1">
																	{totalValue.sampling_count1}
																</Col>
																<Col className="p-1">
																	{totalValue.sampling_count2}
																</Col>

															</Row>
														</Col>
														{curPlan.use_random_draw_percentage ?
															<Col>
															</Col>
															: ''}
														{curPlan.use_random_draw_percentage && curPlan.use_roomtyperandom_draw_percentage ?
															<Col>
															</Col>
															: ''}
														{canWrite ?
															<Col sm="1">
															</Col>
															: ''}
													</Row>
													: ''}
											</Card.Footer>
										</>
									}

								</Card>
							<div className="printonly_visible mb-2">

								<PrintFooter />
							</div>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClosePlan}>
							{t('modal.close')}
						</Button>
						<Button variant="info" onClick={handlePrint}>
							<FontAwesomeIcon icon="print" />  {t('modal.print')}
						</Button>
						{canWrite ?
							<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSavePlan}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderPopover = (t, element) => {


	return (
		<Popover id="popover-basic" className="border border-info">
			<Popover.Body className="">
				<Container fluid>

					<Row>
						<Col className="bg-secondary text-light me-1" sm="6">
							{t('global.labels.roomtype')}
						</Col>
						<Col className="bg-secondary text-light me-1">
							N° locali
						</Col>
						<Col className="bg-secondary text-light me-1">
							{t('checks.labels.extraction_percentage')}
						</Col>
					</Row>
					{Object.keys(element.room_type_dict).map((rt, rtk) => (
						<Row key={rtk} className="mt-1 border-bottom pb-1">
							<Col sm="6">
								{element.room_type_dict[rt].name}
							</Col>
							<Col>
								{element.room_type_dict[rt].count}
							</Col>
							<Col>
								{element.room_type_dict[rt].extraction_percentage}
							</Col>
						</Row>

					))}
				</Container>
			</Popover.Body>
		</Popover>

	)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalRiskAreaParam = ({ element, ui_list, t, functions, canWrite, control_option_list, lqa_list, level_list, is_present, curPlan, isTotal }) => {

	const [showEdit, setShowEdit] = useState(false)
	const [curElement, setCurElement] = useState(element)
	const [editedElement, setEditedElement] = useState(false)
	const { risk_area_dict } = useContext(GlobalDataContext)

	const handleShowEdit = () => {
		setShowEdit(true)
		setEditedElement(false)
	}

	const handleClose = () => {
		setShowEdit(false)
	}

	const handleSave = () => {
		functions.edit(curElement)
		setShowEdit(false)
	}

	const onChangeHandlerElement = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log("CHANGE RA/Total param", name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const onChangeHandlerWeight = (rt_id, el) => {
		let tmp_rt_dict = { ...curElement.room_type_dict }
		tmp_rt_dict[rt_id].extraction_percentage = el.value
		console.log(tmp_rt_dict)
		setCurElement({ ...curElement, room_type_dict: tmp_rt_dict })
		setEditedElement(true)
	}



	return (
		<>
			<Button className="me-1" variant={is_present ? "info" : "outline-info"} size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon={canWrite ? "pencil-alt" : "info-circle"} /> </Button>
			<Modal show={showEdit} onHide={handleClose} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						<FontAwesomeIcon icon="pencil-alt" />
						{isTotal ?
							<span className="text-info">{t('samplingplan.labels.edit_total_param')}</span>
							:
							<>
								<span className="text-info">{t('samplingplan.labels.edit_risk_area_param')}</span>
								<RiskareaBadge riskarea={risk_area_dict[element.id]} />
							</>
						}

						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('samplingplan.labels.prospect')} </Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" value={curElement.level} name="level" onChange={(event) => onChangeHandlerElement(event.target)} >
									{Object.keys(level_list).map((l, k) => (
										<option key={k} value={l}>{level_list[l].label}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('samplingplan.labels.lqa')} </Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" value={curElement.lqa} name="lqa" onChange={(event) => onChangeHandlerElement(event.target)} >
									{Object.keys(lqa_list).map((l, k) => (
										<option key={k} value={l}>{lqa_list[l].label}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curPlan.use_random_draw_percentage && !isTotal ?
							<Form.Group as={Row} controlId="checkName" className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.random_percentage')}</Form.Label>
								<Col sm="8">
									<InputGroup className="mb-2" >
										<Form.Control type="text" name="random_percentage" value={curElement.random_percentage} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />

										<InputGroup.Text id="basic-addon2">%</InputGroup.Text>

									</InputGroup>
								</Col>
							</Form.Group>
							: ''}
						{curPlan.use_random_draw_percentage && curPlan.use_roomtyperandom_draw_percentage && !isTotal ?
							<>
								<Row>
									<Col sm="2">
										{t('samplingplan.labels.roomtyperandom_percentage')}
									</Col>
									<Col>
										<Row>
											<Col className="bg-secondary text-light me-1">
												{t('global.labels.roomtype')}
											</Col>
											<Col className="bg-secondary text-light me-1">
												N° locali (nell'area di rischio)
											</Col>
											<Col className="bg-secondary text-light me-1">
												{t('checks.labels.extraction_percentage')}
											</Col>
										</Row>
										{Object.keys(curElement.room_type_dict).map((rt, rtk) => (
											<Row key={rtk} className="mt-1 border-bottom pb-1">
												<Col>
													{curElement.room_type_dict[rt].name}
												</Col>
												<Col>
													{curElement.room_type_dict[rt].count}
												</Col>
												<Col>
													<InputGroup>
														<Form.Control type="text" name="extraction_percentage" value={curElement.room_type_dict[rt].extraction_percentage} onChange={(event) => onChangeHandlerWeight(rt, event.currentTarget)} />

														<InputGroup.Text id="basic-addon2">%</InputGroup.Text>

													</InputGroup>
												</Col>
											</Row>

										))}
									</Col>
								</Row>
							</>
							: ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>)
}



export const renderPlanPopover = (t, element, result, contract_type, risk_area_list) => {


	return (
		<Popover id="popover-basic" className="border border-info">
			<Popover.Body className="">
				<Container fluid>

					<Row>
						<Col className="bg-secondary text-light me-1" sm="3">
							{contract_type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')}
						</Col>
						<Col className="bg-secondary text-light me-1">
							N° locali
						</Col>
						<Col className="bg-secondary text-light me-1 p-1">
							N° Controlli
						</Col>
						<Col className="bg-secondary text-light me-1">
							LQA
						</Col>
						{result ?
							<>
								<Col className="bg-secondary text-light me-1">
									LQR medio
								</Col>
							</>
							: ''}
					</Row>
					{risk_area_list ?
						risk_area_list.map((r, rtk) => (
							element.element_list[r.Id] ?
								<OnePopoverRow i={element.element_list[r.Id]} key={r.Id} result={result} />
								: '')
						)
						: ''}
				</Container>
			</Popover.Body>
		</Popover>

	)
}




const OnePopoverRow = ({ i, result }) => {
	return (
		<Row className="mt-1 border-bottom pb-1">
			<Col sm="4">
				{i.name}
				<MyBadge color={rgba2hex(i.color)} />
			</Col>
			<Col>
				{i.count}
			</Col>
			<Col className="p-1">
				{result ?
					<>
						{i.check_done || 0}/
					</> : ''}
				{i.data.sampling_count}
			</Col>
			<Col className="p-1">
				{result ?
					<>
						{i.failed_check || 0}/
					</> : ''}
				{i.data[i.lqa].ac}
			</Col>
			{result ?
				<>
					<Col className="p-1">
						{i.average_lqr ? i.average_lqr.toFixed(2) : ''}
					</Col>
				</>
				: ''}
		</Row>
	)
}


const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  @media print{
	  .printonly_visible{
		  display:parent;
	  }
  }
  
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;

